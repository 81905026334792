

const state = {
  label: '',
  name: '',
  path: '',
  breadcrumb: []
}

const mutations = {
  BREADCRUMB (state, menu) {
    // 判断面包屑中是否有重复项
    var isPush = state.breadcrumb.filter(item => {
      return item.label === menu.label
    })
    // 判断面包屑中是否有重复项
    // var isPush = state.breadcrumb.includes(menu)

    if (isPush.length === 0) {
      state.breadcrumb.push(menu)
    }
    // console.log('breadcrumb ===> ', state.breadcrumb)
  }
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
