<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
// import { login } from '@/api/login'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  created () {
    // login().then(res => {
    //   // console.log(res)
    // })
  }
}
</script>

<style>
#app {
}
</style>
