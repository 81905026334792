import Vue from 'vue'
import VueRouter from 'vue-router'

import storage from '@/utils/storage'

Vue.use(VueRouter)

// 处理 面包屑 的重复导航问题
/* 
NavigationDuplicated: Avoided redundant 
navigation to current location: "/userMng/fmlInfo".s
*/
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '*',
    component: () => import('@/views/404')
  },
  {
    path: '/login',
    // component: () => import('@/views/login')
    // component: resolve => require(['@/views/login'], resolve)
    component: () => import('@/views/login')
  },
  {
    path: '/',
    redirect: '/homepage'
  },
  {
    path: '/homepage',
    component: () => import('@/layout'),
    children: [
      // home
      {
        path: '/homepage',
        component: () => import('@/views/homepage')
      },
      // msgBoaMod
      {
        path: '/msgBoaMod/msgShow',
        component: () => import('@/views/msgBoaMod/msgShow')
      },
      {
        path: '/msgBoaMod/msgDeal',
        component: () => import('@/views/msgBoaMod/msgDeal')
      },
      {
        path: '/msgBoaMod/msgLeaving',
        component: () => import('@/views/msgBoaMod/msgLeaving')
      },
      // userMng
      {
        path: '/userMng/userInfo',
        component: () => import('@/views/userMng/userInfo')
      },
      {
        path: '/userMng/fmlInfo',
        component: () => import('@/views/userMng/fmlInfo')
      },
      {
        path: '/userMng/fmlMng',
        component: () => import('@/views/userMng/fmlMng')
      },
      {
        path: '/userMng/mmbMng',
        component: () => import('@/views/userMng/mmbMng')
      },
      // resMng
      {
        path: "/resMng/rent",
        component: () => import('@/views//resMng/rent')
      },
      {
        path: "/resMng/rentHouse",
        component: () => import('@/views/resMng/rentHouse')
      },
      {
        path: "/resMng/rentMng",
        component: () => import('@/views/resMng/rentMng')
      },
      {
        path: "/resMng/work",
        component: () => import('@/views/resMng/work')
      },
      {
        path: "/resMng/recruit",
        component: () => import('@/views/resMng/recruit')
      },
      {
        path: "/resMng/recruitMng",
        component: () => import('@/views/resMng/recruitMng')
      },
      // smMng
      // {
      //   path: "/smMng/antMng",
      //   component: () => import('@/views/smMng/antMng')
      // },
      // {
      //   path: "/smMng/authMng",
      //   component: () => import('@/views/smMng/authMng')
      // },
      // {
      //   path: "/smMng/roleSearch",
      //   component: () => import('@/views/smMng/roleSearch')
      // },
      // {
      //   path: "/smMng/menuMng",
      //   component: () => import('@/views/smMng/menuMng')
      // },
      // {
      //   path: "/smMng/loginLog",
      //   component: () => import('@/views/smMng/loginLog')
      // },
      // vlgAffMng/gnlSlt
      {
        path: '/vlgAffMng/notify',
        component: () => import('@/views/vlgAffMng/notify')
      },
      {
        path: "/vlgAffMng/gnlSlt/election",
        component: () => import('@/views/vlgAffMng/gnlSlt/election')
      },
      {
        path: "/vlgAffMng/gnlSlt/signUp",
        component: () => import('@/views/vlgAffMng/gnlSlt/signUp')
      },
      {
        path: "/vlgAffMng/gnlSlt/vote",
        component: () => import('@/views/vlgAffMng/gnlSlt/vote')
      },
      {
        path: "/vlgAffMng/gnlSlt/eleRes",
        component: () => import('@/views/vlgAffMng/gnlSlt/eleRes')
      },
      // vlgAffMng/vlgDeal
      {
        path: "/vlgAffMng/vlgDeal/vlgMethod",
        component: () => import('@/views/vlgAffMng/vlgDeal/vlgMethod')
      },
      {
        path: "/vlgAffMng/vlgDeal/vlgCheck",
        component: () => import('@/views/vlgAffMng/vlgDeal/vlgCheck')
      },
      {
        path: "/vlgAffMng/vlgDeal/vlgCommit",
        component: () => import('@/views/vlgAffMng/vlgDeal/vlgCommit')
      },
      {
        path: "/vlgAffMng/vlgDeal/vlgOpen",
        component: () => import('@/views/vlgAffMng/vlgDeal/vlgOpen')
      },
      // search
      {
        path: "/search/personInfo",
        component: () => import('@/views/search/personInfo')
      },
      {
        path: "/search/msgInfo",
        component: () => import('@/views/search/msgInfo')
      },
      {
        path: "/search/fmlInfo",
        component: () => import('@/views/search/fmlInfo')
      },
      {
        path: "/search/affInfo",
        component: () => import('@/views/search/affInfo')
      }
    ]
  }
]

// const createRouter = () => new VueRouter({
//   // mode: 'history', // require service support
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRoutes
// })
// export function resetRouter () {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
//   router.options.routes = []
// }



// const router = new VueRouter({})
const createRouter = () => new VueRouter({
  // mode: 'history
  routes
  // https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
  // scrollBehavior (to, from, savePosition) {
  //   console.log('to ==> ', to)
  //   console.log('from ==> ', from)
  //   console.log('savePosition ==> ', savePosition)
  // }
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
  var token = storage.getItem('token')
  console.log('token ===>', token)

  console.log(to.path)
  if (to.path === '/login') {
    return next()
  }

  if (!token) {
    next('/login')
  }

  next()
})

export default router