import { login } from '@/api/login'
import storage from '@/utils/storage'
/* 
  异步： 
    components - dispatch - actions - commit - mutations - state - render - components
  
    同步： 
    components - commit - mutations - state - render - components
*/

const state = {
  token: storage.getItem('token'),
  userinfo: storage.getItem('userinfo')
}

const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload
  },
  SET_USER_INFO(state, payload) {
    state.userinfo = payload
  },
  REMOVE_TOKEN(state) {
    state.token = null
  }
}

const actions = {
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      login({ uuser: payload.username.trim(), upwd: payload.password.trim() })
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            commit('SET_TOKEN', res.data.token)
            commit('SET_USER_INFO', res.data.data[0])
            storage.setItem('token', res.data.token)
            storage.setItem('userinfo', res.data.data[0])
            resolve(res)
          } else {
            resolve(res)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
