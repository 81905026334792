import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// 请求拦截
service.interceptors.request.use(config => {
  return config
}, err => {
  Promise.reject(err)
})

// 响应拦截
service.interceptors.response.use(response => {
  return response
}, err => {
  Message({
    type: 'error',
    message: '网络请求失败！！！'
  })
  Promise.reject(err)
})

export default service
