const namespace = 'mall'

export default {
  setItem (key, value) {
    if (key === '' || value === '') return
    const storage = this.getStorage()
    storage[key] = value
    window.localStorage.setItem(namespace, JSON.stringify(storage))
  },
  getItem (key) {
    return this.getStorage()[key]
  },
  getStorage () {
    return JSON.parse(window.localStorage.getItem(namespace) || '{}')
  },
  clearItem (key) {
    const storage = this.getStorage()
    delete storage[key]
    window.localStorage.setItem(namespace, JSON.stringify(storage))
  },
  clearAll () {
    window.localStorage.setItem(namespace, '{}')
  }
}


// const namespace = 'mall'
// export default {
//   setItem (key, val) {
//     const storage = this.getStorage()
//     storage[key] = val
//     window.localStorage.setItem(namespace, JSON.stringify(storage))
//   },
//   getItem (key) {
//     return this.getStorage()[key]
//   },
//   getStorage () {
//     return JSON.parse(window.localStorage.getItem(namespace) || "{}")
//   },
//   clearItem (key) {
//     const storage = this.getStorage()
//     delete storage[key]
//     window.localStorage.setItem(namespace, JSON.stringify(storage))
//   },
//   clearAll () {
//     window.localStorage.setItem(namespace, '{}')
//   }
// }